import { __postApiData } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ReactEcharts from "echarts-for-react"

const colorData = value => {
  if (value == "Open" || value == "Follow-up" || value == "Converted") {
    return {
      color: "#9de7f8",
    }
  } else if (value == "In-Progress" || value == "Lost") {
    return {
      color: "#fbe293",
    }
  } else if (value == "Closed" || value == "Not Connected") {
    return {
      color: "#f2aeb3",
      fontSize: "5px",
    }
  }
}

const GraphCom = ({ data }) => {
  return (
    <div className=" position-relative rounded-3">
      <ReactEcharts
        style={{ height: "150px" }}
        option={{
          tooltip: {
            trigger: "item",
          },
          legend: {
            bottom: "5%",
            left: "2%",
            orient: "vertical",
            textStyle: {
              color: "#000",
              fontSize: 10,
              fontWeight: "bold",
            },
            itemWidth: 20,
            itemHeight: 20,
            formatter: function (name) {
              const dataItem = data?.find(item => item.lead_status === name)
              return `${name} -${dataItem?.count}`
            },
          },
          toolbox: {
            show: false,
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["40%", "70%"],
              center: ["70%", "60%"],
              startAngle: -90,
              label: {
                show: false,
              },
              data: [
                ...data
                  // ?.filter(item => item.count != 0)
                  .map(value => ({
                    value: value?.count || 0,
                    name: value?.lead_status || " ",
                    itemStyle: colorData(value?.lead_status),
                    label: {
                      fontSize: 11,
                    },
                  })),
              ],
            },
          ],
        }}
      />
    </div>
  )
}
const Overview = ({ campaign }) => {
  const [state, setState] = useState({
    isLoading: false,
    overviewOpen: {
      totalDocuments: null,
      open: "0",
      progress: "0",
      closed: "0",
    },
    overviewProgress: {
      totalDocuments: null,
      follow_up: "0",
      not_connected: "0",
    },
    overviewClosed: {
      totalDocuments: null,
      converted: "0",
      lost: "0",
      closed: "0",
    },
  })

  const { isLoading, overviewOpen, overviewProgress, overviewClosed } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ isLoading: true })

    __postApiData(`/api/v1/admin/g_campaign_detail_ov`, {
      campaign,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          const { overviewOpen, overviewProgress, overviewClosed } = res.data

          updateState({
            overviewOpen: {
              totalDocuments:
                (overviewOpen?.totalDocuments || 0) +
                  (overviewProgress?.totalDocuments || 0) +
                  (overviewClosed?.totalDocuments || 0) || "0",
              open: overviewOpen?.totalDocuments || "0",
              progress: overviewProgress?.totalDocuments || "0",
              closed: overviewClosed?.totalDocuments || "0",
            },
            overviewProgress: {
              totalDocuments: overviewProgress?.totalDocuments || "0",
              follow_up:
                overviewProgress?.statusCounts?.find(
                  element => element?.lead_stage == "Follow-Up"
                )?.count || "0",
              not_connected:
                overviewProgress?.statusCounts?.find(
                  element => element?.lead_stage == "Not-Connected"
                )?.count || "0",
            },
            overviewClosed: {
              totalDocuments: overviewClosed?.totalDocuments || "0",
              converted:
                overviewClosed?.statusCounts?.find(
                  element => element?.lead_stage == "Converted"
                )?.count || "0",
              lost:
                overviewClosed?.statusCounts?.find(
                  element => element?.lead_stage == "Lost"
                )?.count || "0",
              closed:
                overviewClosed?.statusCounts?.find(
                  element => element?.lead_stage == "Closed"
                )?.count || "0",
            },
            isLoading: false,
          })
        } else {
          return updateState({ isLoading: false })
        }
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])
  return (
    <Row>
      <Col md={4}>
        <Card className="rounded-4 pb-4">
          <CardBody className="pb-4">
            <CardTitle className="mb-4">Leads statistics</CardTitle>

            <Row>
              <Col md={4} className=" pe-2">
                <Card className="  bg-secondary-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2">
                  <h5 className=" text-black">
                    {overviewOpen?.totalDocuments || 0}
                  </h5>
                  <h6 className="text-secondary ">Total</h6>
                </Card>
              </Col>
              <Col md={4} className="p-0 pe-2">
                <Card className="  bg-info-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2 ">
                  <h5 className=" text-black">{overviewOpen?.open || 0}</h5>
                  <h6 className="text-info ">Open</h6>
                </Card>
              </Col>
              <Col md={4} className="ps-0 pb-0">
                <Card className="  bg-warning-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2">
                  <h5 className=" text-black">{overviewOpen?.progress || 0}</h5>
                  <h6 className="text-warning "> In-Progress</h6>
                </Card>
              </Col>
              <Col md={4} className="pe-2">
                <Card className="  bg-danger-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2">
                  <h5 className=" text-black">{overviewOpen?.closed || 0}</h5>
                  <h6 className="text-danger "> Closed</h6>
                </Card>
              </Col>
            </Row>
            {overviewOpen?.totalDocuments && (
              <Row>
                <Col>
                  <GraphCom
                    data={[
                      { lead_status: "Open", count: overviewOpen?.open },
                      {
                        lead_status: "In-Progress",
                        count: overviewOpen?.progress,
                      },
                      { lead_status: "Closed", count: overviewOpen?.closed },
                    ]}
                  />
                </Col>
              </Row>
            )}
            {isLoading && (
              <div className="p-2 mt-5">
                <div className="text-center ">
                  <i
                    className="bx bx-loader bx-spin"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="rounded-4 pb-4">
          <CardBody className="pb-4">
            <CardTitle className="mb-4">In-progress Leads</CardTitle>

            <Row>
              <Col md={4} className=" pe-2">
                <Card className="  bg-secondary-subtle bg-gradient  rounded-4 ps-3 py-3">
                  <h5 className=" text-black">
                    {overviewProgress?.totalDocuments || 0}
                  </h5>
                  <h6 className="text-secondary "> Total</h6>
                </Card>
              </Col>
              <Col md={4} className="p-0 pe-2">
                <Card className="  bg-info-subtle bg-gradient  rounded-4 ps-3 py-3">
                  <h5 className=" text-black">
                    {overviewProgress?.follow_up || 0}
                  </h5>
                  <h6 className="text-info "> Follow-up</h6>
                </Card>
              </Col>
              <Col md={4} className="ps-0 ">
                <Card className="  bg-danger-subtle bg-gradient  rounded-4 ps-3 py-3">
                  <h5 className=" text-black">
                    {overviewProgress?.not_connected || 0}
                  </h5>
                  <h6 className="text-danger "> Not Connected</h6>
                </Card>
              </Col>
            </Row>
            {overviewProgress?.totalDocuments && (
              <Row>
                <Col>
                  <GraphCom
                    data={[
                      {
                        lead_status: "Follow-up",
                        count: overviewProgress?.follow_up,
                      },
                      {
                        lead_status: "Not Connected",
                        count: overviewProgress?.not_connected,
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
            {isLoading && (
              <div className="p-2 mt-5">
                <div className="text-center ">
                  <i
                    className="bx bx-loader bx-spin"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="rounded-4 pb-4">
          <CardBody className="pb-4">
            <CardTitle className="mb-4">Closed Leads</CardTitle>
            <Row>
              <Col md={4} className=" pe-2">
                <Card className="  bg-secondary-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2">
                  <h5 className=" text-black">
                    {overviewClosed?.totalDocuments || 0}
                  </h5>
                  <h6 className="text-secondary ">Total</h6>
                </Card>
              </Col>
              <Col md={4} className="p-0 pe-2">
                <Card className="  bg-info-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2 ">
                  <h5 className=" text-black">
                    {overviewClosed?.converted || 0}
                  </h5>
                  <h6 className="text-info ">Converted</h6>
                </Card>
              </Col>
              <Col md={4} className="ps-0 pb-0">
                <Card className="  bg-warning-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2">
                  <h5 className=" text-black">{overviewClosed?.lost || 0}</h5>
                  <h6 className="text-warning "> Lost</h6>
                </Card>
              </Col>
              <Col md={4} className="pe-2">
                <Card className="  bg-danger-subtle bg-gradient  rounded-4 ps-3 py-3 mb-2">
                  <h5 className=" text-black">{overviewClosed?.closed || 0}</h5>
                  <h6 className="text-danger "> By system</h6>
                </Card>
              </Col>
            </Row>

            {overviewClosed?.totalDocuments && (
              <Row>
                <Col>
                  <GraphCom
                    data={[
                      {
                        lead_status: "Converted",
                        count: overviewClosed?.converted,
                      },
                      {
                        lead_status: "Lost",
                        count: overviewClosed?.lost,
                      },
                      { lead_status: "Closed", count: overviewClosed?.closed },
                    ]}
                  />
                </Col>
              </Row>
            )}
            {isLoading && (
              <div className="p-2 mt-5">
                <div className="text-center ">
                  <i
                    className="bx bx-loader bx-spin"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Overview
