import React, { Suspense, useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreatableSelect from "react-select/creatable"
//i18n
import { withTranslation } from "react-i18next"
import { __getApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { useParams } from "react-router-dom"

const UpdateTag = props => {
  const { id, name } = useParams()
  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({})
  const {} = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t(name)} breadcrumbItem={props.t("Tag")} />

          <Card className="rounded-4 ">
            <CardBody>
              <Row className="py-2 border-bottom border-2 mx-2">
                <Col className="ps-0">
                  <CardTitle>Stages & Transitions</CardTitle>
                </Col>
                <Col xl="3" lg={5} md={5} sm="7" className="pe-0">
                  <div className="mb-3 d-flex gap-2">
                    <Input
                      name="name"
                      type="text"
                      placeholder="Add new Stage... "
                      className=" border-0 bg-light rounded-3 p-3"
                      // value={campaign_name}
                      // onChange={e => {
                      //   updateBasic({ campaign_name: e.target.value })
                      // }}
                    />
                    <span className="btn btn-primary d-flex justify-content-center align-items-center px-4">
                      Save
                    </span>
                  </div>
                </Col>
              </Row>
              <CardBox
                name="Open"
                tags={[{ label: "asdas", value: "asdas" }]}
                tags_name="asdas, nhgh"
              />
              <CardBox
                name="In Progress"
                tags={[{ label: "asdas", value: "asdas" }]}
                tags_name="asdas, nhgh"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UpdateTag)

const CardBox = ({ name, tags, tags_name }) => {
  const [state, setState] = useState({ isshow: false })

  const { isshow } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const [inputValue, setInputValue] = React.useState("")
  const [value, setValue] = React.useState(tags)

  const handleKeyDown = event => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.log({ label: inputValue, value: inputValue })
        setValue(prev => [...prev, { label: inputValue, value: inputValue }])
        setInputValue("")
        event.preventDefault()
    }
  }
  return (
    <>
      <Row className="p-3 border border-2 m-2 ">
        <Col className="">
          <CardTitle className="mb-0 h6">{name}</CardTitle>
        </Col>
        <Col xl="6" lg={7} md={7} sm="8" className="mb-0">
          <div className=" d-flex justify-content-between ">
            {isshow ? (
              <span className="btn btn-outline-info ms-auto me-3">Save</span>
            ) : (
              <span>Tag: {tags_name}</span>
            )}
            <span
              onClick={() => {
                updateState({ isshow: !isshow })
              }}
              style={{ cursor: "pointer" }}
              className={`bx bx-caret-${
                isshow ? "up" : "down"
              } font-size-20 align-self-center`}
            />
          </div>
        </Col>

        {isshow && (
          <Col sm="12">
            <Row className=" border-top border-1 mt-3 py-3">
              <Col sm="6" className="border-end border-2">
                <CardText className="text-uppercase h5 text-dark">
                  Transitions
                </CardText>

                {["test", "new Test", "kal ka"].map((opt, i) => (
                  <div key={i + opt} className="form-check mb-1 ms-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={`exampleRadios${"index"}`}
                      id={`exampleRadios${"index" + i}`}
                      value={"option1" + "index"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`exampleRadios${"index" + i}`}
                    >
                      {opt}
                    </label>
                  </div>
                ))}
              </Col>
              <Col sm="6">
                <CardText className="text-uppercase h5 text-dark">TAG</CardText>
                <CreatableSelect
                  components={{
                    DropdownIndicator: null,
                  }}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={newValue => setValue(newValue)}
                  onInputChange={newValue => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type something and press enter..."
                  value={value}
                  className="select2-selection border-0 bg-light rounded-0 p-2"
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  )
}
