import React from "react"
import { Navigate } from "react-router-dom"

const ownerpath = [
  "/home/dashboard",
  "/create-company",
  "/company-list",
  "/team-list",
  "/admin-team",
]
const teampath = []

const Authmiddleware = props => {
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  } else {
    if (localStorage.getItem("T") == "O") {
      if (!ownerpath.includes(window.location.pathname)) {
        return (
          <Navigate
            to={{
              pathname: "/home/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    } else {
      if (ownerpath.includes(window.location.pathname)) {
        return (
          <Navigate
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    }
  }

  console.log("location", window.location.pathname)
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
