import { __postApiData } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap"
import ReactEcharts from "echarts-for-react"
const CampainTagProgress = ({ campaign_type }) => {
  const [state, setState] = useState({
    isLoading: false,
    overview: [],
  })

  const { overview, isLoading } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ isLoading: true, overview: [] })
    __postApiData(`/api/v1/admin/g_campaigns_tag_overview`, { campaign_type })
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({
            isLoading: false,
            overview: res.data,
          })
        } else {
          updateState({
            isLoading: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
        updateState({
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [campaign_type])
  return (
    <Row>
      <Col>
        <Card className="rounded-4 pb-4">
          <CardBody>
            <CardTitle>Lead by Tags</CardTitle>
            {!isLoading && overview.length == 0 && (
              <div className="p-5">
                <div className="text-center ">
                  <i className="bx bx-search" style={{ fontSize: "30px" }}></i>
                </div>
                <div className="text-center mt-3">No Data Found</div>
              </div>
            )}
            <Row>
              {overview.map((item, i) => (
                <Col md={6} className="" key={item.stage}>
                  <CampainTagGraph name={item.stage} list={item.tags} />
                </Col>
              ))}
            </Row>
            {isLoading && (
              <div className="p-5 my-2">
                <div className="text-center ">
                  <i
                    className="bx bx-loader bx-spin"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CampainTagProgress

const CampainTagGraph = ({ list, name }) => {
  return (
    <ReactEcharts
      style={{ height: "250px" }}
      option={{
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "0%",
          left: "left",
          orient: "vertical",
          textStyle: {
            fontSize: 10,
            fontWeight: "normal",
          },
          itemWidth: 15,
          itemHeight: 15,
          formatter: function (name) {
            const dataItem = list?.find(item => item.name === name)
            return `${name} -${dataItem?.value}`
          },
        },
        graphic: [
          {
            type: "text",
            left: "center",
            top: "45%",
            style: {
              text: name,
              textAlign: "center",
              fill: "#333",
              fontSize: 14,
              fontWeight: "normal",
            },
          },
          {
            type: "text",
            left: "center",
            top: "53%",
            style: {
              text: list
                ?.reduce((sum, dataItem) => sum + dataItem?.value, 0)
                .toString(),
              textAlign: "center",
              fill: "#333",
              fontSize: 22,
              fontWeight: "bold",
            },
          },
        ],
        series: [
          {
            name: name,
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 20,
                color: "#000",
                fontWeight: "bold",
                formatter(param) {
                  console.log(param.value)
                  return param.percent + "%"
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: list,
          },
        ],
      }}
    />
  )
}
