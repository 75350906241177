import Loader from "components/Common/Loader"
import Notification from "components/Common/Notification"
import { __postApiData, __putApiData } from "constants/apis"
import React, { Suspense, useState } from "react"
import { Button, CardText, CardTitle, Col, Row } from "reactstrap"

const Questions = React.lazy(() => import("./Questions"))
const QuestionForm = React.lazy(() => import("./QuestionForm"))

const QuestionBox = ({ campaign, campaign_form }) => {
  const [arrayState, setArrayState] = useState(campaign_form?.questions || [])
  const [state, setState] = useState({
    tab: 1,
    section_name: campaign_form?.section_name || "",
    alertMessage: null,
    messageType: null,
    isLoading: false,
    loadingProgress: 10,
  })
  const {
    section_name,
    alertMessage,
    messageType,
    isLoading,
    loadingProgress,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleChangeValue = (index, key, newValue) => {
    setArrayState(prevState => {
      const newArray = [...prevState]
      if (index >= 0 && index < newArray.length) {
        newArray[index] = {
          ...newArray[index],
          [key]: newValue,
        }
      }
      return newArray
    })
  }

  const __handleRemove = index => {
    setArrayState(prevState => {
      return prevState.filter((item, i) => index != i && item)
    })
  }

  const addNewArray = data =>
    setArrayState(prevState => {
      return [...prevState, { ...data }]
    })

  const __handleChangeIndex = (currentIndex, newIndex) => {
    const updatedOptions = [...arrayState]
    const removedObject = updatedOptions.splice(currentIndex, 1)[0]
    updatedOptions.splice(newIndex, 0, removedObject)
    setArrayState(updatedOptions)
  }

  const __handleSubmit = () => {
    console.log(section_name)
    console.log(arrayState)
    let alertdata = []
    if (!section_name.trim()) {
      alertdata.push("Enter campaign name")
    }
    if (arrayState.length == 0) {
      alertdata.push("Add at least one question section")
    }

    if (alertdata.length > 0) {
      return updateState({
        alertMessage: alertdata.join(", "),
        messageType: "danger",
      })
    }

    updateState({
      alertMessage: null,
      messageType: null,
      isLoading: true,
      loadingProgress: 20,
    })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    if (!campaign_form || !campaign_form._id) {
      __postApiData(`/api/v1/admin/c_campaigns_form`, {
        campaign,
        section_name,
        questions: arrayState,
      })
        .then(res => {
          console.log(res)
          if (res.response.response_code === "200") {
            updateState({
              alertMessage: "Campaign Created",
              messageType: "success",
              loadingProgress: 100,
              isLoading: false,
            })
            setTimeout(() => {
              updateState({ alertMessage: null, messageType: null })
            }, 3000)
          } else {
            updateState({ loadingProgress: 100 })
            setTimeout(() => {
              updateState({ isLoading: false })
            }, 1000)
          }
        })
        .catch(error => {
          console.error(error)
          updateState({ loadingProgress: 100 })
          setTimeout(() => {
            updateState({ isLoading: false })
          }, 1000)
        })

      return
    }

    __putApiData(`/api/v1/admin/u_campaigns_form/${campaign_form?._id}`, {
      section_name,
      questions: arrayState,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            alertMessage: "Campaign Update Success",
            messageType: "success",
            loadingProgress: 100,
            isLoading: false,
          })
          setTimeout(() => {
            updateState({ alertMessage: null, messageType: null })
          }, 3000)
        } else {
          updateState({ loadingProgress: 100 })
          setTimeout(() => {
            updateState({ isLoading: false })
          }, 1000)
        }
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
  }

  return (
    <>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader progress={loadingProgress} />}

      <Row className="">
        <Col sm={12}>
          <CardTitle className="bg-dark text-white p-3 my-3">
            QUESTIONS SETTINGS
          </CardTitle>
        </Col>
        <Col sm={12}>
          <CardText className="bg-light  p-2">
            Note: Create question set here. Your executives can ask these
            questions and fill this form, while interacting with customers
          </CardText>
        </Col>

        <Col md={3} className="">
          <Suspense fallback={<></>}>
            <Questions addNewArray={addNewArray} />
          </Suspense>
        </Col>
        <Col md={9} className=" pt-3" style={{ height: "606px" }}>
          <Suspense fallback={<></>}>
            <QuestionForm
              arrayState={arrayState}
              __handleChangeValue={__handleChangeValue}
              __handleRemove={__handleRemove}
              __handleChangeIndex={__handleChangeIndex}
              section_name={section_name}
              updateState={updateState}
            />
          </Suspense>
        </Col>
        <div className="">
          <Button className="btn mt-4 float-end  " onClick={__handleSubmit}>
            Save
          </Button>
        </div>
      </Row>
    </>
  )
}

export default QuestionBox
