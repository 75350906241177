import React, { Suspense, useContext, useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { useParams } from "react-router-dom"
import UploadedFiles from "./comp/UploadedFiles"
import LeadsDistribution from "./comp/LeadsDistribution"
import Overview from "./comp/Overview"

const CampaignsDetails = props => {
  const { id, name } = useParams()
  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({})
  const {} = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Campaign")}
            breadcrumbItem={props.t(name)}
          />
          <Overview campaign={id} />
          <LeadsDistribution campaign={id} />
          <UploadedFiles campaign={id} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CampaignsDetails)
