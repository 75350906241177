import React, { useState } from "react"
import DataContext from "./dataContext"
import { __getApiData } from "constants/apis"

const DataState = props => {
  const [userDetails, setuserDetails] = useState(null)
  const [newNotification, setNotification] = useState([])
  const [notification, setNotificationList] = useState([])
  const [homeOverView, setHomeOverView] = useState(null)
  const [leadOverView, setLeadOverView] = useState({
    isLoaded: false,
  })
  const [callOverView, setCallOverView] = useState({
    isLoaded: false,
  })
  const [sourceFileOverView, setSourceFileOverView] = useState({
    isLoaded: false,
    list: [],
  })
  const [teamOverView, setTeamOverView] = useState({
    isLoaded: false,
  })

  const __handleGetNewNotification = () => {}

  return (
    <DataContext.Provider
      value={{
        userDetails,
        newNotification,
        notification,
        homeOverView,
        setHomeOverView,
        __handleGetNewNotification,
        leadOverView,
        setLeadOverView,
        callOverView,
        setCallOverView,
        sourceFileOverView,
        setSourceFileOverView,
        teamOverView,
        setTeamOverView,
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export default DataState
