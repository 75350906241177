import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { __formatDate, __formatDate2 } from "constants/constantfun"
import TableContainer from "components/Common/TableContainer"
import { Link, useParams } from "react-router-dom"

const CampaignUserReport = props => {
  const { id, name, from_date, to_date } = useParams()

  const [state, setState] = useState({
    list: [],
    isLoading: false,
    showFilter: false,
    from_date: __formatDate2(Number(from_date)),
    to_date: __formatDate2(Number(to_date)),
  })
  const { list, isLoading, showFilter } = state
  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "User",
        accessor: "name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Role</div>,
        accessor: "role",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Date</div>,
        accessor: "date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center">Total Calls Attempted</div>,
        disableFilters: true,
        accessor: "total_call_attempts",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Total Calls Connected</div>,
        disableFilters: true,
        accessor: "total_call_connected",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Closed Leads</div>,
        disableFilters: true,
        accessor: "mark_as_closed",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Rescheduled Leads</div>,
        disableFilters: true,
        accessor: "total_re_scheduled",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
    ],
    []
  )

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ list: [], isLoading: true })

    __postApiData(`/api/v1/admin/g_user_report`, {
      campaign: id,
      from_date: new Date(state.from_date).valueOf(),
      to_date: new Date(state.to_date).valueOf(),
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            list: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
          })
        }
        return updateState({ list: [], isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ list: [], isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Report")}
            breadcrumbItem={props.t("Campaign Users Report")}
          />
          <Card className="rounded-4 pb-4">
            <CardBody>
              <TableContainer
                isGlobalFilter
                columns={columns}
                data={list}
                customPageSize={20}
                filterCom={
                  showFilter ? (
                    <FilterBox
                      state={state}
                      updateState={updateState}
                      handleSubmit={__handleGetUsers}
                    />
                  ) : null
                }
                isAddUserList={"Filter"}
                handleUserClick={() => {
                  updateState({ showFilter: !showFilter })
                }}
                classNameHeader="dark"
              />

              {list?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}

              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CampaignUserReport)

const FilterBox = ({ state, updateState, handleSubmit }) => {
  const { campaign_type, from_date, to_date } = state
  return (
    <Row className="mb-2 ">
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">From Date</Label>
          <Input
            name="name"
            type="date"
            value={from_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ from_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">To Date</Label>
          <Input
            min={from_date}
            name="name"
            type="date"
            value={to_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ to_date: e.target.value })
            }}
          />
        </div>
      </Col>

      <Col sm="12">
        <div className="float-end mb-4 mt-1">
          <Button
            className="me-2 btn bg-secondary  bg-gradient"
            onClick={() => {
              updateState({
                from_date: "",
                to_date: "",
              })
            }}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            className=" btn bg-success  border-success bg-gradient"
          >
            Search
          </Button>
        </div>
      </Col>
    </Row>
  )
}
