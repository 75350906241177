import React, { useContext } from "react"
import { Card, CardBody, CardTitle, Col } from "reactstrap"
import ReactEcharts from "echarts-for-react"
import dataContext from "context/data/dataContext"

const FileOverview = () => {
  const { sourceFileOverView } = useContext(dataContext)
  const { isLoaded, list } = sourceFileOverView

  return (
    <Col md={12}>
      <Card className=" rounded-4">
        <CardBody>
          <CardTitle>Lead Source Summary</CardTitle>
          {isLoaded ? (
            <ReactEcharts
              style={{ height: "450px" }}
              option={{
                tooltip: {
                  trigger: "item",
                },
                legend: {
                  bottom: "0%",
                  left: "left",
                  orient: "vertical",
                  textStyle: {
                    //   color: "#000",
                    fontSize: 12,
                    fontWeight: "normal",
                  },
                  itemWidth: 20,
                  itemHeight: 20,
                  formatter: function (name) {
                    const dataItem = list?.find(item => item.name === name)
                    return `${name} -${dataItem?.value}`
                  },
                },
                graphic: [
                  {
                    type: "text",
                    left: "center",
                    top: "45%",
                    style: {
                      text: "Total",
                      textAlign: "center",
                      fill: "#333", // Text color
                      fontSize: 18, // Font size
                      fontWeight: "normal", // Font weight
                    },
                  },
                  {
                    type: "text",
                    left: "center",
                    top: "50%",
                    style: {
                      text: list
                        ?.reduce((sum, dataItem) => sum + dataItem?.value, 0)
                        .toString(), // Display the total count
                      textAlign: "center",
                      fill: "#333", // Text color
                      fontSize: 28, // Font size
                      fontWeight: "bold", // Font weight
                    },
                  },
                ],
                series: [
                  {
                    name: "",
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    itemStyle: {
                      borderRadius: 10,
                      borderColor: "#fff",
                      borderWidth: 2,
                    },
                    label: {
                      show: false,
                      position: "center",
                    },
                    emphasis: {
                      label: {
                        show: false,
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                        formatter(param) {
                          console.log(param.value)
                          return param.percent + "%"
                        },
                      },
                    },
                    labelLine: {
                      show: false,
                    },
                    data: list,
                  },
                ],
              }}
            />
          ) : (
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{ height: "450px" }}
            >
              <i className="bx bx-loader bx-spin h2 text-black"></i>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

export default FileOverview
