import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import Notification from "components/Common/Notification"
import Loader from "components/Common/Loader"

const CreateCompany = props => {
  const [state, setState] = useState({
    loadingProgress: 10,
    isLoading: false,
    alertMessage: "",
    messageType: "",
  })
  const {
    company_name,
    phone,
    name,
    email,
    password,
    create_limit,
    loadingProgress,
    isLoading,
    alertMessage,
    messageType,
  } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const __handleSubmit = () => {
    if (!company_name) {
      return updateState({
        alertMessage: "Company Name is required",
        messageType: "danger",
      })
    }
    if (!create_limit || create_limit == 0) {
      return updateState({
        alertMessage: "Team create limit is required",
        messageType: "danger",
      })
    }
    if (!name) {
      return updateState({
        alertMessage: "Admin name is required",
        messageType: "danger",
      })
    }
    if (!email) {
      return updateState({
        alertMessage: "Admin email is required",
        messageType: "danger",
      })
    }
    if (!password) {
      return updateState({
        alertMessage: "Admin password is required",
        messageType: "danger",
      })
    }
    if (!phone) {
      return updateState({
        alertMessage: "Admin phone is required",
        messageType: "danger",
      })
    }

    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    __postApiData(`/api/v1/admin/c_admin_user`, {
      company_name,
      phone,
      name,
      email,
      password,
      create_limit,
      role: "ORG_ADMIN",
    })
      .then(res => {
        console.log(res)
        updateState({ loadingProgress: 80 })

        if (res.response.response_code === "200") {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "success",
          })
        } else {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "danger",
          })
        }

        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({
            isLoading: false,
            alertMessage: "Something went wrong",
            messageType: "danger",
          })
        }, 1000)
      })
  }

  return (
    <React.Fragment>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card className="rounded-4 p-3" style={{ minHeight: "70vh" }}>
                <CardBody>
                  <CardTitle className="mb-4 border-bottom border-1 pb-3">
                    Company Details
                  </CardTitle>
                  <Row className="mb-4 ">
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label"> Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={company_name}
                          onChange={e => {
                            updateState({ company_name: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">
                          No. of Team to Create?
                        </Label>
                        <Input
                          name="name"
                          type="number"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={create_limit}
                          onChange={e => {
                            updateState({ create_limit: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <CardTitle className="mb-4 border-bottom border-1 pb-3">
                    Admin Login
                  </CardTitle>

                  <Row className="">
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={name}
                          onChange={e => {
                            updateState({ name: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="name"
                          type="email"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={email}
                          onChange={e => {
                            updateState({ email: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={password}
                          onChange={e => {
                            updateState({ password: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"4"} className="">
                      <div className="mb-3">
                        <Label className="form-label">Phone</Label>
                        <Input
                          name="name"
                          type="number"
                          placeholder=" "
                          className=" border-0 bg-light rounded-0 p-3"
                          value={phone}
                          onChange={e => {
                            updateState({ phone: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Button
                      onClick={__handleSubmit}
                      className="btn bg-info border-0 float-end mt-3 bg-gradient"
                    >
                      Create
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CreateCompany)
