import { __postApiData } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap"
const CampainList = ({ campaign_type }) => {
  const [state, setState] = useState({
    campaigns: null,
    isLoading: false,
  })

  const { campaigns, isLoading } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ campaigns: null, isLoading: true })
    __postApiData(`/api/v1/admin/g_campaigns`, { campaign_type })
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ campaigns: res.data, isLoading: false })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [campaign_type])
  return (
    <Row className="">
      <Col>
        <Card className="rounded-4 ">
          <CardBody>
            <CardTitle className="mb-4">Campaigns</CardTitle>

            {campaigns?.map((item, i) => (
              <Col sm={12} key={item._id}>
                <Link
                  to={`/campaigns/details/${item._id}/${item.campaign_name}`}
                >
                  <Card
                    className="mb-2   rounded-4 p-3 d-flex flex-row align-items-center bg-light"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-success ">
                        <i className="fas fa-bullhorn font-size-14 text-white"></i>
                      </span>
                    </div>
                    <h5 className=" mb-0 ms-2  me-auto">
                      {item.campaign_name}
                    </h5>
                    <i className="bx bx-chevron-right font-size-24"></i>
                  </Card>
                </Link>
              </Col>
            ))}
            {campaigns?.length == 0 && (
              <Col sm={12} className="">
                <Card className="shadow-none">
                  <CardBody className=" d-flex justify-content-center flex-column py-5 mt-3">
                    <div className="text-center ">
                      <i
                        className="bx bx-search"
                        style={{ fontSize: "30px" }}
                      ></i>
                    </div>
                    <div className="text-center mt-3">No Campaign Found</div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {isLoading && (
              <Col sm={12} className="">
                <Card className=" shadow-none">
                  <CardBody className=" d-flex justify-content-center flex-column py-5 mt-3">
                    <div className="p-5">
                      <div className="text-center ">
                        <i
                          className="bx bx-loader bx-spin"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CampainList
