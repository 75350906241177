import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import ReactEcharts from "echarts-for-react"
import { __postApiData } from "constants/apis"

const colorData = value => {
  if (value == "Open") {
    return {
      color: "#9de7f8",
    }
  } else if (value == "In-Progress") {
    return {
      color: "#fbe293",
    }
  } else if (value == "Closed") {
    return {
      color: "#f2aeb3",
    }
  }
}

const GraphCom = ({ data }) => {
  const total_price = data?.statusCounts?.reduce(
    (total, value) => total + value.count,
    0
  )

  return (
    <div className="border border-2 position-relative rounded-4">
      <ReactEcharts
        style={{ height: "250px" }}
        option={{
          tooltip: {
            trigger: "item",
          },

          toolbox: {
            show: false,
          },
          legend: {
            top: "5%",
            left: "2%",
            orient: "vertical",
            textStyle: {
              color: "#000",
              fontSize: 10,
              fontWeight: "bold",
            },
            itemWidth: 20,
            itemHeight: 20,
            formatter: function (name) {
              const dataItem = data?.statusCounts?.find(
                item => item.lead_status === name
              )
              return `${name} -${dataItem?.count}`
            },
          },
          series: [
            {
              name: data?.user_assigned?.name || " ",
              type: "pie",
              radius: ["40%", "70%"],
              center: ["50%", "70%"],
              startAngle: 180,
              label: {
                show: false,
              },
              data: [
                ...data?.statusCounts?.map(value => ({
                  value: value?.count || 0,
                  name: value?.lead_status || " ",
                  itemStyle: colorData(value?.lead_status),
                })),

                {
                  value: total_price,
                  itemStyle: {
                    color: "none",
                    decal: {
                      symbol: "none",
                    },
                  },
                  label: {
                    show: false,
                    fontSize: 12,
                  },
                },
              ],
            },
          ],
        }}
      />
      <div
        className="text-center position-absolute h5 "
        style={{ bottom: "20px", left: "50%", transform: "translateX(-50%)" }}
      >
        <span className="border-bottom border-2 border-dark text-dark">
          {data?.user_assigned?.name} ({total_price})
        </span>
      </div>
    </div>
  )
}

const LeadsDistribution = ({ campaign }) => {
  const [state, setState] = useState({
    isLoading: false,
    userList: [],
  })

  const { isLoading, userList } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ isLoading: true })

    __postApiData(`/api/v1/admin/g_lead_distribution`, {
      campaign,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({
            isLoading: false,
            userList: res.data,
          })
        } else {
          return updateState({ isLoading: false })
        }
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])
  return (
    <Row>
      <Col>
        <Card className="rounded-4 pb-4">
          <CardBody>
            <CardTitle className="mb-4">Lead distribution</CardTitle>
            {userList?.length > 0 && (
              <Row>
                {userList?.map((data, i) => {
                  return (
                    <Col md={4} key={i}>
                      <GraphCom data={data} />
                    </Col>
                  )
                })}
              </Row>
            )}

            {userList?.length == 0 && !isLoading && (
              <div className="p-5">
                <div className="text-center ">
                  <i className="bx bx-search" style={{ fontSize: "30px" }}></i>
                </div>
                <div className="text-center mt-3">No Data Found</div>
              </div>
            )}

            {isLoading && (
              <div className="p-5">
                <div className="text-center ">
                  <i
                    className="bx bx-loader bx-spin"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default LeadsDistribution
