import React, { Suspense, useContext, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { useParams } from "react-router-dom"
import { __formatDate } from "constants/constantfun"
const pathId = {
  1: "Feedback",
  2: "Other",
  3: "Reminder",
  4: "Sales",
  5: "Service",
}

const initallState = {
  overviewOpen: {
    totalDocuments: "0",
    open: "0",
    progress: "0",
    closed: "0",
  },
  overviewProgress: {
    totalDocuments: "0",
    follow_up: "0",
    not_connected: "0",
  },
  overviewClosed: {
    totalDocuments: "0",
    converted: "0",
    lost: "0",
    closed: "0",
  },
  campaignsDetails: null,
}

const LeadReport = props => {
  const {} = useContext(dataContext)
  const [state, setState] = useState({
    isLoading: false,
    ...initallState,
    campaigns: [],
    campaign_id: "",
  })
  const {
    campaigns,
    campaign_id,
    overviewOpen,
    overviewProgress,
    overviewClosed,
    campaignsDetails,
    isLoading,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    __getApiData(`/api/v1/admin/g_campaigns_list`)
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ campaigns: res.data })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetDetails = () => {
    if (!campaign_id) {
      window.alert("Select campaign")
      return
    }
    updateState({
      ...initallState,
      isLoading: true,
    })

    __postApiData(`/api/v1/admin/g_campaign_detail_ov`, {
      campaign: campaign_id,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          // updateState({ campaigns: res.data })
          const {
            overviewOpen,
            overviewProgress,
            overviewClosed,
            campaignsDetails,
          } = res.data

          updateState({
            overviewOpen: {
              totalDocuments:
                (overviewOpen?.totalDocuments || 0) +
                  (overviewProgress?.totalDocuments || 0) +
                  (overviewClosed?.totalDocuments || 0) || "0",
              open: overviewOpen?.totalDocuments || "0",
              progress: overviewProgress?.totalDocuments || "0",
              closed: overviewClosed?.totalDocuments || "0",
            },
            overviewProgress: {
              totalDocuments: overviewProgress?.totalDocuments || "0",
              follow_up:
                overviewProgress?.statusCounts?.find(
                  element => element?.lead_stage == "Follow-Up"
                )?.count || "0",
              not_connected:
                overviewProgress?.statusCounts?.find(
                  element => element?.lead_stage == "Not-Connected"
                )?.count || "0",
            },
            overviewClosed: {
              totalDocuments: overviewClosed?.totalDocuments || "0",
              converted:
                overviewClosed?.statusCounts?.find(
                  element => element?.lead_stage == "Converted"
                )?.count || "0",
              lost:
                overviewClosed?.statusCounts?.find(
                  element => element?.lead_stage == "Lost"
                )?.count || "0",
              closed:
                overviewClosed?.statusCounts?.find(
                  element => element?.lead_stage == "Closed"
                )?.count || "0",
            },
            isLoading: false,
            campaignsDetails: campaignsDetails,
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Report")}
            breadcrumbItem={props.t("Campaign Lead Report")}
          />
          <Row>
            <Col>
              {/* <Col sm={12} className="">
                <CardTitle className="bg-dark text-white p-3 my-1 mt-0">
                  <span className="text-uppercase h6">Lead by Tags</span>
                </CardTitle>
              </Col> */}
              <Col>
                <Card className="rounded-4 pb-4">
                  <CardBody>
                    <CardTitle className="mb-3"> Lead by Tags</CardTitle>
                    {searchBox()}
                    {details()}
                  </CardBody>
                </Card>
                <Row>
                  <Col md="5">
                    <Card className="rounded-4 pb-4">
                      <CardBody>
                        <CardTitle className="mb-3"> NOT CONNECTED</CardTitle>
                        <Row>
                          <Col md="6">
                            <div className=" d-flex justify-content-center flex-column align-items-center p-3 rounded">
                              <h6>lost</h6>

                              {isLoading ? (
                                <i
                                  className="bx bx-loader bx-spin mt-1"
                                  style={{ fontSize: "22px" }}
                                ></i>
                              ) : (
                                <h4>{overviewClosed?.closed}</h4>
                              )}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className=" d-flex justify-content-center flex-column align-items-center p-3 rounded">
                              <h6>Pending</h6>

                              {isLoading ? (
                                <i
                                  className="bx bx-loader bx-spin mt-1"
                                  style={{ fontSize: "22px" }}
                                ></i>
                              ) : (
                                <h4>{overviewProgress?.not_connected}</h4>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="rounded-4 pb-4">
                      <CardBody>
                        <CardTitle className="mb-3">
                          YES, CONNECTED LEADS
                        </CardTitle>
                        <Row>
                          <Col md="4">
                            <div className=" d-flex justify-content-center flex-column align-items-center p-3 rounded">
                              <h6>Follow-Up</h6>

                              {isLoading ? (
                                <i
                                  className="bx bx-loader bx-spin mt-1"
                                  style={{ fontSize: "22px" }}
                                ></i>
                              ) : (
                                <h4>{overviewProgress?.follow_up}</h4>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="  d-flex justify-content-center flex-column align-items-center p-3 rounded">
                              <h6>Converted</h6>
                              {isLoading ? (
                                <i
                                  className="bx bx-loader bx-spin mt-1"
                                  style={{ fontSize: "22px" }}
                                ></i>
                              ) : (
                                <h4>{overviewClosed?.converted}</h4>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className=" d-flex justify-content-center flex-column align-items-center p-3 rounded">
                              <h6>Lost</h6>

                              {isLoading ? (
                                <i
                                  className="bx bx-loader bx-spin mt-1"
                                  style={{ fontSize: "22px" }}
                                ></i>
                              ) : (
                                <h4>{overviewClosed?.lost}</h4>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Col>
            {camDetails()}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

  function details(params) {
    return (
      <Row className="mb-4">
        <Col md="3">
          <Card className="  bg-primary-subtle bg-gradient  rounded-4 p-4 py-3">
            {isLoading ? (
              <h2 className=" text-primary">
                <i
                  className="bx bx-loader bx-spin mt-1"
                  style={{ fontSize: "22px" }}
                ></i>
              </h2>
            ) : (
              <h2 className=" text-black">{overviewOpen?.open}</h2>
            )}
            <h6 className="text-primary ">Open</h6>
          </Card>
        </Col>
        <Col md="3">
          <Card className="  bg-warning-subtle bg-gradient  rounded-4 p-4 py-3">
            {isLoading ? (
              <h2 className=" text-warning">
                <i
                  className="bx bx-loader bx-spin mt-1"
                  style={{ fontSize: "22px" }}
                ></i>
              </h2>
            ) : (
              <h2 className=" text-black">{overviewOpen?.progress}</h2>
            )}
            <h6 className="text-warning ">In-Progress</h6>
          </Card>
        </Col>
        <Col md="3">
          <Card className="  bg-danger-subtle bg-gradient  rounded-4 p-4 py-3">
            {isLoading ? (
              <h2 className=" text-danger">
                <i
                  className="bx bx-loader bx-spin mt-1"
                  style={{ fontSize: "22px" }}
                ></i>
              </h2>
            ) : (
              <h2 className=" text-black">{overviewOpen?.closed}</h2>
            )}
            <h6 className="text-danger ">Closed</h6>
          </Card>
        </Col>
        <Col md="3">
          <Card className="  bg-info-subtle bg-gradient  rounded-4 p-4 py-3">
            {isLoading ? (
              <h2 className=" text-info">
                <i
                  className="bx bx-loader bx-spin mt-1"
                  style={{ fontSize: "22px" }}
                ></i>
              </h2>
            ) : (
              <h2 className=" text-black">{overviewOpen?.totalDocuments}</h2>
            )}
            <h6 className="text-info ">Total</h6>
          </Card>
        </Col>
      </Row>
    )
  }

  function searchBox(params) {
    return (
      <Row className="mb-4">
        <Col sm={"10"} className="">
          <div className="mb-3">
            <select
              className="form-select border-0 bg-light rounded-0 p-3"
              name="select"
              value={campaign_id}
              onChange={e => {
                console.log(e.target.value)
                updateState({ campaign_id: e.target.value })
              }}
            >
              <option value="">Choose Campaign...</option>

              {campaigns?.map(item => (
                <option key={item._id} value={item._id}>
                  {item.campaign_name}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col sm={1} className="d-flex align-items-center">
          <Button className="btn bg-primary" onClick={__handleGetDetails}>
            Search
          </Button>
        </Col>
      </Row>
    )
  }

  function camDetails(params) {
    return (
      <Col md={4}>
        <Col>
          <Card className="rounded-4 pb-4">
            <CardBody>
              <CardTitle className="mb-3">CAMPAIGN DETAILS</CardTitle>

              {campaignsDetails ? (
                <>
                  <Row className="mt-4">
                    <Col md="12">
                      <Card className="mb-0  shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                        <h6 className="text-secondary mb-0 ">Manager Name:</h6>

                        <h6 className=" text-black mb-0">
                          {campaignsDetails?.campaign_manager[0]?.name || ""}
                        </h6>
                      </Card>
                    </Col>
                    <Col md="12">
                      <Card className="mb-0  shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                        <h6 className="text-secondary mb-0 ">Campaign Name:</h6>

                        <h6 className=" text-black mb-0">
                          {campaignsDetails?.campaign_name || ""}
                        </h6>
                      </Card>
                    </Col>
                    <Col md="12">
                      <Card className="mb-0  shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                        <h6 className="text-secondary mb-0 ">Campaign Type:</h6>

                        <h6 className=" text-black mb-0">
                          {pathId[campaignsDetails?.campaign_type] || ""}
                        </h6>
                      </Card>
                    </Col>
                    <Col md="12">
                      <Card className="mb-0  shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                        <h6 className="text-secondary mb-0 ">Date Created:</h6>

                        <h6 className=" text-black mb-0">
                          {campaignsDetails?.createdAt
                            ? __formatDate(campaignsDetails?.createdAt)
                            : ""}
                        </h6>
                      </Card>
                    </Col>
                    <Col md="12">
                      <Card className="mb-0  shadow-none rounded-4 p-2 d-flex justify-content-between flex-row align-items-center">
                        <h6 className="text-secondary mb-0 ">Status:</h6>
                        <div className="p-0 m-0 d-flex align-items-center">
                          <span
                            className="bg-success bg-gradient me-2"
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50px",
                            }}
                          />
                          <h6 className=" text-black mb-0">Active</h6>{" "}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : null}
              {isLoading && (
                <div className="p-5 mt-4">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Col>
    )
  }
}

export default withTranslation()(LeadReport)
