import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import { __deleteApiData, __postApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle } from "reactstrap"

const UploadedFiles = ({ campaign }) => {
  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "File Name",
        accessor: "file_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Total Records</div>,
        accessor: "total_records",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },

      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                {/* <li>
                  <Link
                    className="btn btn-sm btn-soft-info"
                    to={`/leads/${cellProps.value}/${data.file_name}`}
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </Link>
                </li> */}
                <li>
                  <span
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      updateState({
                        isDelete: true,
                        delete_id: cellProps.value,
                      })
                    }}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )

  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isDelete: false,
    delete_id: null,
  })

  const { leads, isLoading, isDelete, delete_id } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ leads: null, isLoading: true })

    __postApiData(`/api/v1/admin/g_leads`, {
      campaign,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
          })
        }
        return updateState({ leads: [], isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ leads: [], isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])

  const __handleDeleteLeads = () => {
    __deleteApiData(`/api/v1/admin/d_lead_contacts/${delete_id}`)
      .then(res => {
        if (res.response.response_code === "200") {
          __handleGetUsers()
          return updateState({ isDelete: false })
        }
        return updateState({ isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }

  return (
    <>
      <DeleteModal
        show={isDelete}
        onDeleteClick={__handleDeleteLeads}
        onCloseClick={() => updateState({ isDelete: false, delete_id: null })}
      />

      <Card className="rounded-4 pb-4">
        <CardBody>
          <CardTitle className="mb-3">Uploaded Files</CardTitle>
          {!isLoading && leads?.length > 0 && (
            <TableContainer
              columns={columns}
              data={leads}
              customPageSize={20}
            />
          )}

          {leads?.length == 0 && !isLoading && (
            <div className="p-5">
              <div className="text-center ">
                <i className="bx bx-search" style={{ fontSize: "30px" }}></i>
              </div>
              <div className="text-center mt-3">No Leads Found</div>
            </div>
          )}

          {isLoading && (
            <div className="p-5">
              <div className="text-center ">
                <i
                  className="bx bx-loader bx-spin"
                  style={{ fontSize: "30px" }}
                ></i>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default UploadedFiles
