import Loader from "components/Common/Loader"
import Notification from "components/Common/Notification"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate2 } from "constants/constantfun"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const EditBox = ({ edit, closeButton, isEdit, completeFun }) => {
  const [state, setState] = useState({
    loadingProgress: 10,
    isLoading: false,
    alertMessage: "",
    messageType: "",
  })

  const {
    loadingProgress,
    isLoading,
    alertMessage,
    messageType,
    company_name,
    limit,
    _id,
  } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  useEffect(() => {
    edit && updateState({ ...edit })
  }, [edit])

  const __handleUpdateLead = () => {
    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    __putApiData(`/api/v1/admin/u_admin_limit`, {
      _id,
      company_name,
      limit,
    })
      .then(res => {
        updateState({
          loadingProgress: 100,
        })
        if (res.response.response_code === "200") {
          completeFun()
          setTimeout(() => {
            updateState({
              alertMessage: res.response.response_message,
              messageType: "success",
              isLoading: false,
            })
          }, 1000)
        } else {
          setTimeout(() => {
            updateState({
              alertMessage: res.response.response_message,
              messageType: "danger",
              isLoading: false,
            })
          }, 1000)
        }
      })
      .catch(error => {
        console.error(error)
        updateState({
          isLoading: false,
          alertMessage: "Update failed!",
          messageType: "danger",
        })
      })
  }

  return (
    <>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      <Modal isOpen={isEdit} centered size="xl">
        <ModalHeader toggle={closeButton}>Edit Company Details</ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} className=" pe-4">
              <Row className=" pt-3">
                <div className="mb-3 col-md-6">
                  <Label className="form-label"> Company Name:</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Name... "
                    className=" border-0 bg-light rounded-0 p-3"
                    value={company_name}
                    onChange={e => {
                      updateState({ company_name: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Team Limit</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=" "
                    className=" border-0 bg-light rounded-0 p-3"
                    value={limit}
                    onChange={e => {
                      updateState({ limit: e.target.value })
                    }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
            <Button
              className="btn btn-outline-primary bg-white text-primary "
              onClick={closeButton}
            >
              Cancel
            </Button>
            <Button
              className="btn bg-primary border-primary "
              onClick={__handleUpdateLead}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditBox
