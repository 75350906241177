import React from "react"
import { Navigate } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

import Dashboard from "../pages/Dashboard/index"
import Users from "pages/Users"
import Campaigns from "pages/Campaigns"
import AddCampaigns from "pages/AddCampaigns"
import CampaignsDetails from "pages/CampaignsDetails"
import Contact from "pages/Contact"
import EditCampaigns from "pages/EditCampaigns"
import LeadList from "pages/LeadList"
import LeadReport from "pages/LeadReport"
import CampaignReport from "pages/CampaignReport"
import CampaignUserReport from "pages/CampaignReport/CampaignUserReport"
import UserCallReport from "pages/UserCallReport"
import WalkInForm from "pages/WalkInForm"
import OwnerHome from "pages/OwnerHome"
import LoginAdmin from "pages/Authentication/LoginAdmin"
import CreateCompany from "pages/CreateCompany"
import CompanyList from "pages/CompanyList"
import TeamList from "pages/TeamList"
import AdminTeam from "pages/TeamList/AdminTeam"
import LoginOwner from "pages/Authentication/LoginOwner"
import UpdateTag from "pages/UpdateTag"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/manage-users", component: <Users /> },
  { path: "/campaigns/:id", component: <Campaigns /> },
  { path: "/create", component: <AddCampaigns /> },
  { path: "/campaigns/details/:id/:name", component: <CampaignsDetails /> },
  { path: "/contact", component: <Contact /> },
  { path: "/campaigns/edit/:id", component: <EditCampaigns /> },
  { path: "/leads/:id/:name", component: <LeadList /> },
  { path: "/lead-report", component: <LeadReport /> },
  { path: "/campaign-report", component: <CampaignReport /> },
  { path: "/user-call-report", component: <UserCallReport /> },
  { path: "/walk-in-form", component: <WalkInForm /> },
  { path: "/tag/:name", component: <UpdateTag /> },
  {
    path: "/campaign-user-report/:id/:name/:from_date/:to_date",
    component: <CampaignUserReport />,
  },
  {
    path: "/home/dashboard",
    component: <OwnerHome />,
  },
  {
    path: "/create-company",
    component: <CreateCompany />,
  },
  {
    path: "/company-list",
    component: <CompanyList />,
  },
  {
    path: "/team-list",
    component: <TeamList />,
  },
  {
    path: "/admin-team",
    component: <AdminTeam />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "*",
    exact: true,
    component: (
      <Navigate
        to={localStorage.getItem("T") == "O" ? "/home/dashboard" : "/dashboard"}
      />
    ),
  },
]

const publicRoutes = [
  { path: "/login/owner/:token/:name/:type", component: <LoginOwner /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/login/admin", component: <LoginAdmin /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
