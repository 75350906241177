import TableContainer from "components/Common/TableContainer"
import React, { useEffect, useMemo, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { __formatDate } from "constants/constantfun"
import { __deleteApiData, __getApiData, __postApiData } from "constants/apis"
import Notification from "components/Common/Notification"
import Loader from "components/Common/Loader"
import DeleteModal from "components/Common/DeleteModal"

const UsersList = () => {
  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          console.log(cellProps)
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <>{cellProps.value || "-"}</>
        },
      },
      {
        Header: <div className="text-center">Employees Id</div>,
        accessor: "employees_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center">Phone</div>,
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Role</div>,
        accessor: "role",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center">Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },

      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                {/* <li>
                  <a
                    className="btn btn-sm btn-soft-info"
                    id="edittooltip-10"
                    href="#"
                  >
                    <i className="mdi mdi-pencil-outline"></i>
                  </a>
                </li> */}
                <li>
                  <a
                    className="btn btn-sm btn-soft-danger"
                    id="deletetooltip-10"
                    href="#"
                    onClick={() => {
                      // __handleDeleteUser(cellProps.value)
                      updateState({
                        isDelete: true,
                        delete_id: cellProps.value,
                      })
                    }}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </a>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleUserClicks = () => {
    updateState({ modal1: true })
  }

  const [state, setState] = useState({
    modal1: false,
    role: "",
    employees_id: "",
    name: "",
    phone: "",
    email: "",
    password: "",
    manager: null,
    managerOptions: [],
    userList: [],
    loadingProgress: 10,
    isLoading: false,
    alertMessage: "",
    messageType: "",
    isDelete: false,
    delete_id: null,
  })
  const {
    userList,
    modal1,
    manager,
    password,
    email,
    phone,
    name,
    employees_id,
    role,
    loadingProgress,
    isLoading,
    alertMessage,
    messageType,
    isDelete,
    delete_id,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))
  const toggle1 = () => updateState({ modal1: !modal1 })

  const __handleGetUsers = () => {
    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)
    __getApiData(`/api/v1/admin/g_all_users`)
      .then(res => {
        console.log(res)
        updateState({ loadingProgress: 80 })

        if (res.response.response_code === "200") {
          const managers = res.data
            .filter(user => user.role === "MANAGER")
            .map(manager => ({ name: manager.name, _id: manager._id }))
          console.log(managers)
          updateState({ userList: res.data, managerOptions: managers })
        } else {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "danger",
          })
        }

        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
  }

  const __handleSubmit = () => {
    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)
    __postApiData(`/api/v1/admin/c_user`, {
      password,
      email,
      phone,
      name,
      employees_id,
      role,
    })
      .then(res => {
        console.log(res)
        updateState({ loadingProgress: 80 })
        if (res.response.response_code === "200") {
          __handleGetUsers()
          updateState({ modal1: false })
        } else {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "danger",
          })
        }
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
  }
  const __handleDeleteUser = () => {
    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)
    __deleteApiData(`/api/v1/admin/d_user/${delete_id}`)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          __handleGetUsers()
        } else {
          updateState({
            alertMessage: res.response.response_message,
            messageType: "danger",
          })
        }
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false, isDelete: false })
        }, 1000)
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false, isDelete: false })
        }, 1000)
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])

  return (
    <>
      <DeleteModal
        show={isDelete}
        onDeleteClick={__handleDeleteUser}
        onCloseClick={() => updateState({ isDelete: false, delete_id: null })}
      />
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      <Card className=" rounded-4  py-3">
        <CardBody>
          <TableContainer
            columns={columns}
            data={userList}
            customPageSize={20}
            isGlobalFilter={true}
            isAddUserList={"Add User"}
            classNameHeader="dark"
            handleUserClick={__handleUserClicks}
            // isNextPrvButtonsEnabled
          />
        </CardBody>
      </Card>
      {modelBox1()}
    </>
  )
  function modelBox1() {
    return (
      <Modal isOpen={modal1} toggle={toggle1} centered>
        <ModalBody className="rounded-4">
          <div className="p-3 bg-dark mb-4">
            <CardTitle className="text-white text-center">Add User</CardTitle>
          </div>
          <Row>
            <Col md={"6"} className="">
              {/* <input type="text" className="  shadow-sm " /> */}
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=" "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={name}
                  onChange={e => {
                    updateState({ name: e.target.value })
                  }}
                />
                {/* {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null} */}
              </div>
            </Col>
            <Col md={"6"} className="">
              {/* <input type="text" className="  shadow-sm " /> */}
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=" "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={email}
                  onChange={e => {
                    updateState({ email: e.target.value })
                  }}
                />
                {/* {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null} */}
              </div>
            </Col>
            <Col md={"6"} className="">
              {/* <input type="text" className="  shadow-sm " /> */}
              <div className="mb-3">
                <Label className="form-label">Phone</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=" "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={phone}
                  onChange={e => {
                    updateState({ phone: e.target.value })
                  }}
                />
                {/* {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null} */}
              </div>
            </Col>
            <Col md={"6"} className="">
              {/* <input type="text" className="  shadow-sm " /> */}
              <div className="mb-3">
                <Label className="form-label">Role</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  name="select"
                  value={role}
                  onChange={e => {
                    updateState({ role: e.target.value })
                  }}
                >
                  <option value="0">Choose...</option>
                  {["MANAGER", "ASST_MANAGER", "EXECUTIVE"].map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col md={"6"} className="">
              {/* <input type="text" className="  shadow-sm " /> */}
              <div className="mb-3">
                <Label className="form-label">Password</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=" "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={password}
                  onChange={e => {
                    updateState({ password: e.target.value })
                  }}
                />
                {/* {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null} */}
              </div>
            </Col>
            <Col md={"6"} className="">
              {/* <input type="text" className="  shadow-sm " /> */}
              <div className="mb-3">
                <Label className="form-label">Employees Id</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=" "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={employees_id}
                  onChange={e => {
                    updateState({ employees_id: e.target.value })
                  }}
                />
                {/* {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null} */}
              </div>
            </Col>
            {/* {role == "ORG_ADMIN" || role == "MANAGER" || role == "" ? null : (
              <Col md={"6"} className="">
                <div className="mb-3">
                  <Label className="form-label">Manager</Label>
                  <select
                    className="form-select border-0 bg-light rounded-0 p-3"
                    name="select"
                    value={manager}
                    onChange={e => {
                      updateState({ manager: e.target.value })
                    }}
                  >
                    <option value="0">Choose...</option>
                    {[{ _id: "65829e7d7adfb0040e149acb", name: "User" }].map(
                      item => (
                        <option key={item} value={item._id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </Col>
            )} */}
          </Row>

          <div className="d-flex gap-4 mt-3 justify-content-end">
            <Button
              className="btn btn-warning bg-gradient "
              onClick={() => updateState({ modal1: false })}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-dark bg-gradient "
              onClick={__handleSubmit}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default UsersList
