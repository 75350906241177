import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"

// import images
import logodark from "./square-logo.png"
import logolight from "./square-logo.png"
import imagebg from "./spash.png"
import CarouselPage from "./CarouselPage"
import { __hasAnyValue } from "constants/constantfun"
import { HashLoader } from "react-spinners"
import { __postApiData } from "constants/apis"

const Login = () => {
  let navigate = useNavigate()

  const [passwordShow, setPasswordShow] = useState(false)
  const [userdetails, setuser] = useState({
    user_id: "user@gmail.com",
    password: "1234",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})

  document.title = "Login - RiLead (Your Path to Success)"

  const __handleSubmit = () => {
    let alertdata = { user_id: "", password: "" }

    if (!userdetails.user_id.trim()) {
      alertdata.user_id = "Fill your username or email id"
    }
    if (!userdetails.password) {
      alertdata.password = "Fill your password"
    }
    setError(prv => ({
      ...prv,
      ...alertdata,
    }))

    if (__hasAnyValue(alertdata)) {
      return
    }
    setLoading(true)

    __postApiData("/api/v1/admin/l_admin_user", userdetails)
      .then(res => {
        console.log(res)
        setLoading(false)

        if (res.response.response_code === "200") {
          localStorage.setItem("authUser", res.data.authtoken)
          localStorage.setItem("name", res.data?.user?.name)

          navigate("/dashboard")
        } else if (res.response.response_code === "400") {
          setError(prv => ({
            ...prv,
            user_id: " ",
            password: res.response.response_message,
          }))
        } else if (res.response.response_code === "500") {
          setError(prv => ({
            ...prv,
            user_id: " ",
            password: res.response.response_message,
          }))
        }
      })
      .catch(error => {
        setLoading(false)
        setError(prv => ({
          ...prv,
          user_id: " ",
          password: "Some Technical Issue!\n Please try after some time",
        }))
        console.error(error)
      })
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col
              xl={4}
              className="position-relative"
              style={{
                backgroundImage: `url(${imagebg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              {/* <img src={imagebg} alt=""> */}
              <div
                className="auth-full-page-content p-md-5 p-4"
                style={{ background: "rgba(255,255,255,0.85)" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="#" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="100"
                          className="logo-dark-element bg-white"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="100"
                          className="logo-light-element bg-white"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to Ri-Lead.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            !loading && __handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email address"
                              type="text"
                              value={userdetails.user_id}
                              onChange={e => {
                                setuser(prev => ({
                                  ...prev,
                                  user_id: e.target.value,
                                }))
                              }}
                              invalid={error.user_id ? true : false}
                            />

                            <FormFeedback type="invalid">
                              {error.user_id}
                            </FormFeedback>
                          </div>

                          <div className="mb-3">
                            {/* <div className="float-end">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div> */}
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              value={userdetails.password}
                              onChange={e => {
                                setuser(prev => ({
                                  ...prev,
                                  password: e.target.value,
                                }))
                              }}
                              invalid={error.password ? true : false}
                            />

                            <FormFeedback type="invalid">
                              {error.password}
                            </FormFeedback>
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block d-flex justify-content-center"
                              type="submit"
                            >
                              {loading ? (
                                <HashLoader color="#fff" size={20} />
                              ) : (
                                "Log In"
                              )}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} All rights reserved by RI
                        Websoft Pvt. Ltd.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* </img> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

  function loader(params) {
    return (
      <div className="position-absolute top-0 bottom-0 right-0 left-0">
        loading
      </div>
    )
  }
}

export default Login
