import { __postApiData } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap"
import ReactEcharts from "echarts-for-react"
const colorData = value => {
  if (value == "Open") {
    return {
      color: "#34c38f",
    }
  } else if (value == "In-Progress") {
    return {
      color: "#f1b44c",
    }
  } else if (value == "Closed") {
    return {
      color: "#f46a6a",
    }
  }
}
const CampainProgress = ({ campaign_type }) => {
  const [state, setState] = useState({
    isLoading: false,
    overview: [],
    overall: {},
  })

  const { overview, overall, isLoading } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ isLoading: true, overview: [], overall: {} })
    __postApiData(`/api/v1/admin/g_campaigns_overview`, { campaign_type })
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({
            isLoading: false,
            overview: [
              {
                value: res.data?.Open || 0,
                name: "OPEN",
                itemStyle: {
                  color: "#9de7f8",
                },
              },
              {
                value: res.data?.["In-Progress"] || 0,
                name: "IN PROGRESS",
                itemStyle: {
                  color: "#fbe293",
                },
              },
              {
                value: res.data?.Closed || 0,
                name: "CLOSED",
                itemStyle: {
                  color: "#f2aeb3",
                },
              },
            ],
            overall: res.data,
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [campaign_type])
  return (
    <Row>
      <Col>
        <Card className="rounded-4 pb-4">
          <CardBody>
            <CardTitle> Lead Funnel by stages</CardTitle>
            <Row>
              <Col md={6}>
                <div className="d-flex  align-items-center justify-content-center flex-column text-center">
                  <div
                    className=" bg-warning-subtle bg-gradient rounded-4 p-4 py-3 mt-5"
                    style={{ width: "220px" }}
                  >
                    {isLoading ? (
                      <h2 className=" text-warning">
                        <i
                          className="bx bx-loader bx-spin mt-1"
                          style={{ fontSize: "22px" }}
                        ></i>
                      </h2>
                    ) : (
                      <h2 className=" text-black">
                        {overall?.totalDocuments || 0}
                      </h2>
                    )}
                    <h6 className="text-warning ">Total Leads</h6>
                  </div>
                  <div
                    className="bg-info-subtle bg-gradient rounded-4 p-4 py-3  mt-4"
                    style={{ width: "220px" }}
                  >
                    {isLoading ? (
                      <h2 className=" text-info">
                        <i
                          className="bx bx-loader bx-spin mt-1"
                          style={{ fontSize: "22px" }}
                        ></i>
                      </h2>
                    ) : (
                      <h2 className=" text-black">
                        {overall?.["In-Progress"] || 0}
                      </h2>
                    )}
                    <h6 className="text-info ">Total In-Progress</h6>
                  </div>
                  <div
                    className="bg-danger-subtle bg-gradient rounded-4 p-4 py-3 mt-4"
                    style={{ width: "220px" }}
                  >
                    {isLoading ? (
                      <h2 className=" text-danger">
                        <i
                          className="bx bx-loader bx-spin mt-1"
                          style={{ fontSize: "22px" }}
                        ></i>
                      </h2>
                    ) : (
                      <h2 className=" text-black">{overall?.Closed || 0}</h2>
                    )}
                    <h6 className="text-danger ">Total Closed</h6>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <ReactEcharts
                  style={{ height: "350px" }}
                  option={{
                    legend: {
                      top: "bottom",
                    },
                    toolbox: {
                      show: false,
                    },
                    tooltip: {
                      trigger: "item",
                      formatter: "{a} <br/>{b} : {c} ({d}%)",
                    },
                    series: [
                      {
                        name: "Lead Funnel by stages",
                        type: "pie",
                        radius: [20, 100],
                        center: ["50%", "50%"],
                        roseType: "area",
                        itemStyle: {
                          borderRadius: 8,
                        },
                        data: overview,
                        itemStyle: {
                          emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                          },
                        },
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CampainProgress
