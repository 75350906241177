import React, { useEffect } from "react"
import withRouter from "components/Common/withRouter"

//redux
import { useNavigate, useParams } from "react-router-dom"

const LoginOwner = () => {
  const { token, name } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()

    setTimeout(() => {
      localStorage.setItem("authUser", token)
      localStorage.setItem("name", name)
      localStorage.setItem("T", "O")
      navigate("/home/dashboard")
    }, 100)
  }, [])

  return <></>
}

export default withRouter(LoginOwner)
