import { __getApiData, __postApiData } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import Notification from "components/Common/Notification"
import Loader from "components/Common/Loader"

const AddContact = () => {
  const [state, setState] = useState({
    file_name: "",
    campaign_select: 2,
    campaigns: [],
    campaign: null,
    campaign_type: 1,
    numbers: [],
    alertMessage: "",
    messageType: "",
    isLoading: false,
    loadingProgress: 10,
  })
  const {
    file_name,
    campaign_select,
    campaigns,
    campaign,
    numbers,
    campaign_type,
    messageType,
    alertMessage,
    isLoading,
    loadingProgress,
  } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    __getApiData(`/api/v1/admin/g_campaigns_list`)
      .then(res => {
        if (res.response.response_code === "200") {
          const campaigns = res.data.map(campaign => ({
            label: campaign.campaign_name,
            value: campaign._id,
          }))
          console.log(campaigns)
          updateState({ campaigns })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleFileUpload = e => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()
    // exl
    // reader.onload = event => {
    //   const binaryString = event.target.result
    //   const workbook = XLSX.read(binaryString, { type: "binary" })

    //   const sheetName = workbook.SheetNames[0]
    //   const sheet = workbook.Sheets[sheetName]
    //   const data = XLSX.utils.sheet_to_json(sheet)
    //   console.log(data)

    //   // setExcelData(data)
    // }
    // reader.readAsBinaryString(file)

    // csv
    reader.onload = e => {
      const content = e.target.result
      const rows = content.split("\n")
      const data = rows.map(row => row.split(","))

      const headers = data[0]
      console.log(headers)
      const parsedData = data.slice(1).map(row => {
        return headers.reduce((obj, header, index) => {
          obj[header] = row[index]
          return obj
        }, {})
      })
      console.log(parsedData)
      updateState({ numbers: parsedData })
    }

    reader.readAsText(file)
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])

  const __handleUpload = () => {
    const newData = {
      file_name,
      numbers,
      campaign_select,
    }
    if (campaign_select == 1) {
      newData.campaign_type = campaign_type
    }
    if (campaign_select == 2) {
      newData.campaign = campaign?.value
    }
    updateState({ isLoading: true, loadingProgress: 10 })
    console.log(newData)
    __postApiData(`/api/v1/admin/u_contact`, newData)
      .then(res => {
        console.log(res)
        updateState({ isLoading: false, loadingProgress: 100 })

        if (res.response.response_code === "200") {
          updateState({ alertMessage: "Success", messageType: "success" })
        }
      })
      .catch(error => {
        console.error(error)
        updateState({ isLoading: false, loadingProgress: 100 })
      })
  }

  return (
    <>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      {!file_name && selectFile()}
      {file_name && uploadForm()}
    </>
  )
  function uploadForm() {
    return (
      <Row>
        <Col md={"12"} className="">
          <div className="mb-3">
            <Label className="form-label">File Name</Label>
            <Input
              name="name"
              type="text"
              placeholder="Name... "
              className=" border-0 bg-light rounded-0 p-3"
              value={file_name}
              onChange={e => {
                updateState({ file_name: e.target.value })
              }}
            />
          </div>
        </Col>
        {/* <Col md={"12"} className="">
          <div className="mb-3">
            <Label className="form-label">Campaign Action</Label>
            <div className="ms-5 mb-3 mt-2">
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`exampleRadios`}
                  id={`exampleRadios`}
                  defaultChecked={true}
                  checked={campaign_select == 1 ? true : false}
                  onClick={e => {
                    updateState({ campaign_select: 1 })
                  }}
                />
                <label className="form-check-label" htmlFor={`exampleRadios`}>
                  Create a new Campaign
                </label>
              </div>
            </div>

            <div className="ms-5 mt-3 mb-4">
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`exampleRadios`}
                  id={`exampleRadios2`}
                  checked={campaign_select == 2 ? true : false}
                  onClick={e => {
                    updateState({ campaign_select: 2 })
                  }}
                />
                <label className="form-check-label" htmlFor={`exampleRadios2`}>
                  Add leads in existing campaign
                </label>
              </div>
            </div>
          </div>
        </Col> */}
        {campaign_select == 1 && (
          <Col md={"12"} className="">
            <div className="mb-3">
              <Label className="form-label">Campaign Type</Label>
              <select
                className="form-select border-0 bg-light rounded-0 p-3"
                name="select"
                value={campaign_type}
                onChange={e => {
                  console.log(e.target.value)
                  updateState({ campaign_type: e.target.value })
                }}
              >
                <option value="">Choose...</option>
                {[
                  { name: "Feedback", _id: "1" },
                  { name: "Other", _id: "2" },
                  { name: "Reminder", _id: "3" },
                  { name: "Sales", _id: "4" },
                  { name: "Service", _id: "5" },
                ].map(item => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        )}
        {campaign_select == 2 && (
          <Col md={"12"} className="">
            <div className="mb-3">
              <label className="control-label">Campaign Name</label>
              <Select
                value={campaign}
                // isMulti={true}
                onChange={e => {
                  updateState({ campaign: e })
                  console.log(e)
                }}
                options={campaigns}
                className="select2-selection border-0 bg-light rounded-0 p-2"
              />
            </div>
          </Col>
        )}

        <Col md={12}>
          <div className=" float-end">
            <Button
              onClick={() => __handleUpload()}
              className="btn bg-primary  bg-gradient border-primary mt-3 mb-1"
            >
              Save Leads
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
  function selectFile() {
    return (
      <div className=" ">
        <div className="input-group mb-3">
          <label
            htmlFor="inputGroupFile01"
            className="p-5 border w-100 text-center"
            style={{ cursor: "pointer" }}
          >
            <div className="mb-3">
              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
            </div>
            <h5 className="text-secondary">Upload Your Contact/Lead File</h5>
            <span></span>
          </label>
          <Input
            id="inputGroupFile01"
            type="file"
            className="form-control form-control d-none"
            // accept=".xlsx, .xls, .csv"
            accept=".csv"
            onChange={e => {
              handleFileUpload(e)
              updateState({ file_name: e.target.files[0].name })
            }}
          />
        </div>
        <span>
          <a
            href="/Sample_contacts_file.csv"
            className=" d-flex align-items-center justify-content-end"
            download
          >
            <i className="bx bx-download h5 me-2 mt-2"></i> Download Sample Data
          </a>
        </span>
      </div>
    )
  }
}

export default AddContact
